define("discourse/plugins/discourse-chat-integration/admin/models/rule", ["exports", "@glimmer/tracking", "discourse/models/category", "discourse/models/rest", "I18n"], function (_exports, _tracking, _category, _rest, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let Rule = _exports.default = (_class = class Rule extends _rest.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "type", _descriptor, this);
      _initializerDefineProperty(this, "category_id", _descriptor2, this);
      _initializerDefineProperty(this, "tags", _descriptor3, this);
      _initializerDefineProperty(this, "channel_id", _descriptor4, this);
      _initializerDefineProperty(this, "filter", _descriptor5, this);
      _initializerDefineProperty(this, "error_key", _descriptor6, this);
      _defineProperty(this, "available_types", [{
        id: "normal",
        name: _I18n.default.t("chat_integration.type.normal")
      }, {
        id: "group_message",
        name: _I18n.default.t("chat_integration.type.group_message")
      }, {
        id: "group_mention",
        name: _I18n.default.t("chat_integration.type.group_mention")
      }]);
    }
    get available_filters() {
      const available = [];
      const provider = this.channel.provider;
      if (provider === "slack") {
        available.push({
          id: "thread",
          name: _I18n.default.t("chat_integration.filter.thread"),
          icon: "chevron-right"
        });
      }
      available.push({
        id: "watch",
        name: _I18n.default.t("chat_integration.filter.watch"),
        icon: "exclamation-circle"
      }, {
        id: "follow",
        name: _I18n.default.t("chat_integration.filter.follow"),
        icon: "circle"
      }, {
        id: "tag_added",
        name: _I18n.default.t("chat_integration.filter.tag_added"),
        icon: "tag"
      }, {
        id: "mute",
        name: _I18n.default.t("chat_integration.filter.mute"),
        icon: "times-circle"
      });
      return available;
    }
    get category() {
      const categoryId = this.category_id;
      if (categoryId) {
        return _category.default.findById(categoryId);
      } else {
        return false;
      }
    }
    get filterName() {
      return _I18n.default.t(`chat_integration.filter.${this.filter}`);
    }
    updateProperties() {
      return this.getProperties(["type", "category_id", "group_id", "tags", "filter"]);
    }
    createProperties() {
      return this.getProperties(["type", "channel_id", "category_id", "group_id", "tags", "filter"]);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "type", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "normal";
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "category_id", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tags", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "channel_id", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "filter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "watch";
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "error_key", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class);
});